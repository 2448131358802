import Vue from 'vue'
import App from './App.vue'
import router from './router'
// firebase
import './firebase'
import store from './store'
import vuetify from './plugins/vuetify';
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCDAIq29Fm8Kua8hZ-0MGJ8UdpRMk9zUoI",
    libraries: "places", // necessary for places input
  },
});
// As a plugin
import VueMask from "v-mask";

Vue.use(VueMask, {
  placeholders: {
    "#": null, // passing `null` removes default placeholder, so `#` is treated as character
    D: /\d/, // define new placeholder
    Я: /[\wа-яА-Я]/, // cyrillic letter as a placeholder
  },
});
/* Moment injection */
import moment from "moment";
Object.defineProperty(Vue.prototype, "$moment", {
  value: moment,
});

Vue.config.productionTip = false
// navigation guards before each
router.beforeEach((to, from, next) => {

	if (to.matched.some(record => record.meta.requiresAuth)) {
		// this route requires auth, check if logged in
		// if not, redirect to login page.
		if (store.getters.user != null) {
			next()
		}else if(store.getters.userAdmin!=null){
			next()
		} 
		else {
			next('/')	
			} 
		}else{
      next()
    }

})
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
