<template>
  <v-carousel :show-arrows="false" hide-delimiters cycle height="100%">
    <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src">
      <!-- <p class="containerText">Sadaii.com</p>
      <p class="subText">Estilo en cada momento</p> -->
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      items: [
        {
          src:
            "https://firebasestorage.googleapis.com/v0/b/sai-shop-6c49b.appspot.com/o/banner1.png?alt=media&token=b498f404-c488-4d97-b461-5a1e84067057",
        },

        {
          src:
            "https://static.e-stradivarius.net/5/static2/homes/2020_rebajas_OI_1/img/mkt-w/1920/no-venta/2400_rebajas.jpg?t=20210127003001",
        },
      ],
    };
  },
};
</script>
<style lang="css">
.inspire {
  height: 100vh;
}
.textReduction {
  font-size: 4em;
}
/* @media handheld, only screen and (max-width: 463px) {
  .containerText {
    display: flex;
    justify-content: center;

    margin-top: 50%;
  }
} */
.containerText {
  display: flex;
  justify-content: center;

  margin-top: 30%;
  font-weight: bold;
  font-size: 220%;
  color: black;
}
.subText {
  display: flex;
  justify-content: center;

  font-weight: bold;
  font-size: 230%;
  color: black;
}
</style>
