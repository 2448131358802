export const user = state => {

    return state.user;
}
export const userAdmin = state => {

  return state.userAdmin;
}

export const users=state=>{
  return state.users;
}
export const products=state=>{
    return state.products;
}
export const productsWithPorcent=state=>{
  if(state.user !=null && state.user.percent!= undefined){
    
    return state.products.map(p=>{        
        p.pricePorcent = p.price * ( state.user.percent/100)
        return p;
    });
  }
  return state.products;
}
export const menus=state=>{
  return state.menus;
}
export const carCount=state=>{
  if(state.user==null){
    return 0;
  }
    return state.carts.filter(c=>c.userId==state.user.uid).length;
}
export const getCartByUser=state=>{
  if(state.user==null){
    return;
  }

  return state.carts.filter(c=>c.userId==state.user.uid);
}
export const carts=state=>{
  return state.carts;
}
export const requests=state=>{
  return state.requests;
}
export const requestsByUser=state=>{
  return state.requestsByUser;
}
export const totalCart= state => state.carts.reduce((currentQuantiy, cart) => currentQuantiy + cart.quantity, 0);

export const totalAmount= (state) =>{
  var cartsByUser=state.carts.filter(c=>c.userId==state.user.uid);
   return cartsByUser.reduce(
    (currentAmount, cart) => currentAmount + cart.quantity * cart.price,
    0
  );
}
 