import Vue from 'vue';
// import Nprogress from 'nprogress';
import router from "@/router";

export const login = (state, user) => {
    // console.log(user)
    state.user = user;
}

export const loginUserSuccess = (state, user) => {
    state.user = user;
    
    localStorage.setItem('user', user);
    router.push("/profile").catch(err => {});
    
}
export const loginUserAdminSuccess = (state, userAdmin) => {
  state.userAdmin = userAdmin;
  localStorage.setItem('userAdmin', userAdmin);
  router.push("/home").catch(err => {});
  
}
export const logoutUserAdmin = (state) => {
  router.push("/").catch(err => {});;
  state.userAdmin = null
  localStorage.removeItem('userAdmin');
}
export const signUpUserSuccess = (state, user) => {
    state.user = localStorage.setItem('user', user);
}


export const logoutUser = (state) => {
    router.push("/").catch(err => {});;
    state.user = null
    localStorage.removeItem('user');
}
export const updateProducts=(state, products)=>{
  state.products = products;
  localStorage.setItem('products', products);
}
export const updateUsers=(state, users)=>{
  state.users = users;
  localStorage.setItem('users', users);
}
export const updateUserMutation=(state, user)=>{
  state.user = user;
  localStorage.setItem('user', user);
}
export const updateRequestsByUser=(state, requestsByUser)=>{
  state.requestsByUser = requestsByUser;
  localStorage.setItem('requestsByUser', requestsByUser);
}
export const updateRequests=(state, requests)=>{
  state.requests = requests;
  localStorage.setItem('requests', requests);
}
export const updateMenu=(state,menus)=>{
  state.menus = menus;
  localStorage.setItem('menus', menus);
}
export const ADD_PRODUCT_TO_CART=(state, product)=> {
    const carts = [...state.carts]
    const cartIndex = carts.findIndex((cart) => cart.id === product.id)
 
    if (cartIndex !== -1) {
      carts[cartIndex]
    } else {
      carts.push({ ...product})
    }

    state.carts = [...carts]
  
    localStorage.setItem('carts', JSON.stringify(state.carts))
  };
export const REMOVE_PRODUCT_FROM_CART=(state, id)=> {
    const carts = [...state.carts]
    const cartIndex = carts.findIndex((cart) => cart.id === id)
  
    if (cartIndex !== -1) {
      const item = carts[cartIndex]

      if (item.quantity === 1) {
        carts.splice(cartIndex, 1)
      } else {
        carts.splice(cartIndex, 1)
      }
    }

    state.carts = [...carts]

    localStorage.setItem('carts', JSON.stringify(state.carts))
  };
  export const clearDataCart=(state)=>{
    state.carts = [];
    localStorage.removeItem('carts');
  }
export const clearData = state => {
    state.user = null;
    state.products = []; 
};
