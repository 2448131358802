import {
    STORAGE_KEY
} from "./state";

const localStoragePlugin = store => {
    store.subscribe((mutation, state) => {
        const syncedData = {
            user: state.user,
            carts:state.carts,
            products:state.products,
            requests:state.requests,
            requestsByUser:state.requestsByUser,
            menus:state.menus,
            users:state.users,
            userAdmin:state.userAdmin
        };

        localStorage.setItem(STORAGE_KEY, JSON.stringify(syncedData));

        if (mutation.type === "clearData") {
            localStorage.removeItem(STORAGE_KEY);
        }
    });
};

export default [localStoragePlugin];