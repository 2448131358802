import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import "firebase/storage";
 // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
 var config = {
    apiKey: "AIzaSyDfvVogex7U4EFdRaZIu50KcQNnSlo_sPE",
    authDomain: "sai-shop-6c49b.firebaseapp.com",
    projectId: "sai-shop-6c49b",
    storageBucket: "sai-shop-6c49b.appspot.com",
    messagingSenderId: "664245003029",
    appId: "1:664245003029:web:af842f1d9ac4d9852e7f1c",
    measurementId: "G-G0RTD4QF4Z"
  };
firebase.initializeApp(config);
const auth = firebase.auth();
const storage = firebase.storage();
const firestore = firebase.firestore();
export {
    firebase,
    auth,  
    firestore,
    storage
};