import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/",
      component: (resolve) => {
        require(["../views/Start"], resolve);
      },
    children: [
      {
        path: "/",
        component: (resolve) => {
          require(["../views/Home"], resolve);
        },
      },
      {
          path: '/login',
          name: 'login',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '../components/Login/Login.vue')
      },
      {
        path: '/slide',
        name: 'slide',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/exampleSlide.vue')
    },
      {
           path: '/register',
            name: 'register',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ '../components/Login/Register.vue')
      },
      {
        path: '/healp',
         name: 'Healp',
         // route level code-splitting
         // this generates a separate chunk (about.[hash].js) for this route
         // which is lazy-loaded when the route is visited.
         component: () => import(/* webpackChunkName: "about" */ '../views/Healp.vue')
   },
      {
           path: '/profile',
            name: 'Profile',
            meta: {
              requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "about" */ '../views/Profile.vue')
      },
      {
           path: '/product/:filter',
            name: 'product',
            // props: (route) => ({
            //   ...route.params
            // }),
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ '../views/Products.vue')
      },
      {
           path: '/productDetail/:id',
            name: 'productDetail',
            // props: (route) => ({
            //   ...route.params
            // }),
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ '../views/ProductDetail.vue')
      },
      {
        path: '/cart',
         name: 'cart',
         // props: (route) => ({
         //   ...route.params
         // }),
         // route level code-splitting
         // this generates a separate chunk (about.[hash].js) for this route
         // which is lazy-loaded when the route is visited.
         component: () => import(/* webpackChunkName: "about" */ '..//components/Car/CarDialog.vue')
   },
 

    ],
  },
  {
    path: "/admin",
    component: (resolve) => {
      require(["../views/admin/Admin"], resolve);
    },
    children: [
      {
        path: "/",
        component: (resolve) => {
          require(["../views/admin/Login"], resolve);
        },
        meta: {
          redirectIfLogged: true,
        },
      },
      {
        path: "/client",
        component: (resolve) => {
          require(["../views/admin/Client"], resolve);
        },
        meta: {
          requiresAuth: true
        },
      },
      {
        path: "/requests",
        component: (resolve) => {
          require(["../views/admin/Requests"], resolve);
        },
        meta: {
          requiresAuth: true
        },
      },
      {
        path: "/requestsCompleted",
        component: (resolve) => {
          require(["../views/admin/CompletedRequest"], resolve);
        },
        meta: {
          requiresAuth: true
        },
      },
       {
        path: "/home",
        component: (resolve) => {
          require(["../views/admin/Home"], resolve);
        },
        meta: {
          requiresAuth: true
        },
      },
      {
        path: "/product",
        component: (resolve) => {
          require(["../views/admin/Product"], resolve);
        },
        meta: {
          requiresAuth: true
        },
      },



    ],
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
