import * as mutations from "./mutations";
import * as actions from "./actions";
import * as getters from "./getters";
import { state } from "./state";
import plugins from "./plugins";
import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
const store = new Vuex.Store({
  state,
  actions,
  getters,
  mutations,
  plugins,
});

export default store;