import {
    auth,
    firestore,
    firebase
} from "@/firebase";

const products = firestore.collection('products');
const users = firestore.collection('users');
const requests=firestore.collection('requests');
const menus=firestore.collection('menus');
export const signinUserInFirebase = (context, payload) => {
    const {
        user,
        alternate
    } = payload;
  
    return auth.signInWithEmailAndPassword(user.email, user.password)
        .then(credentials => {
            const {
                uid,
                email
            } = credentials.user
            return Promise.resolve({
                uid,
                email
            })
        })
        .catch(error => {
            return Promise.reject({
                error
            })
        });
}
export const signinUserAdminInFirebase = (context, payload) => {
    const {
        user,
        alternate
    } = payload;
  
    return auth.signInWithEmailAndPassword(user.email, user.password)
        .then(credentials => {
            const {
                uid,
                email
            } = credentials.user
            context.commit('loginUserAdminSuccess',user)
            return Promise.resolve({
                uid,
                email
            })
        })
        .catch(error => {
            return Promise.reject({
                error
            })
        });
}
export const signupUserInFirebase = async (store, user) => {
   var response=await  auth.createUserWithEmailAndPassword(user.email, user.password);
   return response.user.uid;
    
}
export const createUser = (context, user) => {
    context.commit('loginUserSuccess',user)
    user.created = firebase.firestore.Timestamp.now()
    return users
        .doc(user.uid)
        .set(user);
}
export const getUserByEmail=async(context, email)=>{
    try {
        var data={};
        let userRef = await users.where("email","==",email).get();
        if(userRef.docs.length==0){
            return false;
        }
        for (let product of userRef.docs) {
            data= product.data()
        }
        context.commit('loginUserSuccess',data)
        return data;
    }
    catch(err) {
        console.log('Error getting documents', err);
        return false
    }
}
export const updatedUser=(context, user)=>{
    context.commit('updateUserMutation',user)
    return users
        .doc(user.uid)
        .set(user);
}

export const getUsers = ({
    commit
}) => {
    return users.get()
        .then(querySnapshot => {
            const documents = querySnapshot.docs;
            const users = documents.map(d => {
                return {
                    id: d.id,
                    ...d.data(),
                }
            });
             
            return commit('updateUsers', users);
        });
}
export const createProduct = (store, product) => {
    product.created = firebase.firestore.Timestamp.now()
    product.id = Math.floor(
        Math.random() * (1000000000 - 10000000 + 100000) + 10000000
      );
    return products
        .doc(product.id)
        .set(product);
}
export const deleteProductAction = (store, product) => {

    return products
        .doc(product.id).delete()
}
export const createRequest=async(store, request)=>{
    try {
        request.created = firebase.firestore.Timestamp.now();
        request.status = "Proceso";
       requests
              .doc(request.idRequest)
              .set(request);
        return 200;
    } catch (error) {
       
        return 500;
    }
}
export const updateRequest=async(store, request)=>{
    try {
       requests
              .doc(request.idRequest)
              .set(request);
        return 200;
    } catch (error) {
       
        return 500;
    }
}
export const createMenu=(context,menu)=>{
    try {

        menus
              .doc()
              .set(menu);
        return 200;
    } catch (error) {
       
        return 500;
    }
}
export const getMenus = ({
    commit
}) => {
    return menus.get()
        .then(querySnapshot => {
            const documents = querySnapshot.docs;
            const menus = documents.map(d => {
                return {
                    id: d.id,
                    ...d.data(),
                }
            });
             
            return commit('updateMenu', menus);
        });
}
export const getRequestsByUser = (context,userId) => {
   
    return requests.where("userId", "==", userId).get()
        .then(querySnapshot => {
            const documents = querySnapshot.docs;
            const requests = documents.map(d => {
                return {
                    id: d.id,
                    ...d.data(),
                }
            });
           
            return context.commit('updateRequestsByUser', requests);
        });
}
export const getRequests = ({
    commit
}) => {
    return requests.get()
        .then(querySnapshot => {
            const documents = querySnapshot.docs;
            const requests = documents.map(d => {
                return {
                    id: d.id,
                    ...d.data(),
                }
            });
             
            return commit('updateRequests', requests);
        });
}
export const getProducts = ({
    commit
}) => {
    return products.get()
        .then(querySnapshot => {
            const documents = querySnapshot.docs;
            const products = documents.map(d => {
                return {
                    id: d.id,
                    ...d.data(),
                }
            });
        
            return commit('updateProducts', products);
        });
}
export const addProductToCart=(context, product)=> {
 
    context.commit('ADD_PRODUCT_TO_CART', product)
  };
export const removeProductFromCart=(context, productId)=> {
    context.commit('REMOVE_PRODUCT_FROM_CART', productId)
  };
export const removeCartData=(context)=>{
    context.commit('clearDataCart')
}
export const logoutUserFromFirebase = (context) => {
    auth.signOut()
        .then(() => {
            
            setTimeout(() => {
                context.commit('logoutUser');
                context.commit('clearData')
            }, 500)
        })
        .catch(error => {
            context.commit('loginUserFailure', error);
        })
}
export const logoutUserAdminFromFirebase = (context) => {
    auth.signOut()
        .then(() => {
            
            setTimeout(() => {
                context.commit('logoutUserAdmin');
            }, 500)
        })
        .catch(error => {
            context.commit('loginUserFailure', error);
        })
}