export const STORAGE_KEY = "sizee-app";

let syncedData = {
    user: null,
    userAdmin:null,
    users:[],
    carts:[],
    products: [],
    requests:[],
    requestsByUser:[],
    menus:[],
    
};

if (localStorage.getItem(STORAGE_KEY)) {
    syncedData = { ...syncedData, ...JSON.parse(localStorage.getItem(STORAGE_KEY))};
}

export const state = syncedData;